<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  version="1.1"
  x="0px"
  y="0px"
  viewBox="50 0 100 100"
  enable-background="new 0 0 100 100"
  xml:space="preserve">
  <path
    d=" M80.452,45.199
    c-0.182-0.002-4.457-0.126-5.317-6.275c-1.521-10.876-2.444-16.058-4.905-20.027c-1.635-2.639-4.127-5.763-8.936-6.506
    c-1.091-0.165-2.114,0.579-2.282,1.671c-0.169,1.092,0.579,2.113,1.671,2.282c2.992,0.462,4.621,2.196,6.146,4.659
    c2.06,3.321,2.905,8.18,4.345,18.476c0.844,6.039,4.441,8.593,7.254,9.407v2.236c-2.813,0.814-6.41,3.361-7.254,9.403
    C69.734,70.821,68.889,75.68,66.829,79c-1.525,2.462-3.154,4.196-6.146,4.658c-1.092,0.169-1.84,1.19-1.671,2.282
    c0.152,0.988,1.004,1.694,1.974,1.694c0.102,0,0.205-0.008,0.309-0.023c4.809-0.743,7.301-3.867,8.935-6.504
    c2.462-3.969,3.386-9.15,4.906-20.028c0.83-5.936,4.883-6.26,5.317-6.276c0.068-0.001,0.132-0.015,0.198-0.022
    c0.064-0.007,0.13-0.009,0.191-0.021c0.068-0.015,0.131-0.04,0.196-0.061c0.06-0.02,0.121-0.034,0.179-0.059
    c0.063-0.026,0.119-0.063,0.179-0.097c0.054-0.03,0.109-0.055,0.159-0.089c0.057-0.039,0.105-0.087,0.158-0.131
    c0.045-0.038,0.095-0.072,0.137-0.115c0.048-0.049,0.088-0.105,0.131-0.159c0.037-0.046,0.078-0.089,0.111-0.138
    c0.038-0.058,0.067-0.122,0.1-0.185c0.027-0.052,0.06-0.102,0.082-0.156c0.028-0.065,0.044-0.137,0.064-0.206
    c0.017-0.057,0.039-0.11,0.051-0.169c0.014-0.071,0.016-0.146,0.021-0.221c0.005-0.058,0.018-0.113,0.018-0.172v-5.604
    C82.428,46.104,81.547,45.213,80.452,45.199z" />
</svg>

<!-- built from logoSVG, bit hacky -->

<style>
  svg {
    fill: currentColor;
    width: 100%;
    height: 100%;
  }
</style>

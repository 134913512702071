<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  version="1.1"
  x="0px"
  y="0px"
  viewBox="0 0 50 100"
  enable-background="new 0 0 100 100"
  xml:space="preserve">
  <path
    d="M28.826,39.476C30.266,29.18,31.111,24.321,33.171,21c1.525-2.462,3.154-4.196,6.146-4.658
    c1.092-0.169,1.84-1.19,1.671-2.282s-1.192-1.84-2.282-1.671c-4.809,0.743-7.301,3.867-8.935,6.505
    c-2.462,3.97-3.386,9.151-4.906,20.027c-0.83,5.936-4.883,6.26-5.317,6.276c-0.068,0.001-0.132,0.015-0.198,0.022
    c-0.064,0.007-0.13,0.009-0.191,0.021c-0.068,0.015-0.131,0.04-0.196,0.061c-0.06,0.02-0.121,0.034-0.179,0.059
    c-0.063,0.026-0.119,0.063-0.179,0.097c-0.054,0.03-0.109,0.055-0.159,0.089c-0.057,0.039-0.105,0.087-0.158,0.131
    c-0.045,0.038-0.095,0.072-0.137,0.115c-0.048,0.049-0.088,0.105-0.131,0.159c-0.037,0.046-0.078,0.089-0.111,0.138
    c-0.038,0.058-0.067,0.122-0.1,0.185c-0.027,0.052-0.06,0.102-0.082,0.156c-0.028,0.065-0.044,0.137-0.064,0.206
    c-0.017,0.057-0.039,0.11-0.051,0.169c-0.014,0.071-0.016,0.146-0.021,0.221c-0.005,0.058-0.018,0.113-0.018,0.172v5.604
    c0,1.075,0.877,1.959,1.951,1.999c0.459,0.018,4.512,0.342,5.342,6.277c1.521,10.876,2.444,16.058,4.905,20.026
    c1.635,2.639,4.127,5.763,8.936,6.506c0.104,0.016,0.207,0.023,0.309,0.023c0.97,0,1.821-0.706,1.974-1.694
    c0.169-1.092-0.579-2.113-1.671-2.282c-2.992-0.462-4.621-2.196-6.147-4.659c-2.059-3.32-2.904-8.179-4.344-18.475
    c-0.844-6.039-4.441-8.594-7.254-9.408v-2.236C24.386,48.063,27.982,45.517,28.826,39.476z" />
</svg>

<!-- built from logoSVG, bit hacky -->

<style>
  svg {
    fill: currentColor;
    width: 100%;
    height: 100%;
  }
</style>

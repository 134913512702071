<script>
  import Header from "./Header.svelte";
  import Footer from "./Footer.svelte";
</script>

<div class="layout">
  <Header />
  <main>
    <slot />
  </main>
  <Footer />
</div>

<style>
  .layout {
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
  }
  main {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 var(--gap-2);
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  :global(.page) {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: var(--gap-2);
    padding-top: var(--gap-2);
    padding-bottom: var(--gap-3);
    /* more padding at bottom to life page a little */
  }
</style>

<div class="page">
  <section>
    <h3>About Me</h3>
    <p>
      I'm originally from France. I've lived in New York, London, Amsterdam and
      Barcelona.
    </p>
    <p>I love to build cool apps, travel and stay active!</p>
  </section>
  <section>
    <h3>Contact</h3>
    <p>
      Please email me at:
      <br />
      <strong>thomas [at] collardeau [dot] com</strong>
      <br />
      I'd love to hear from you!
    </p>
  </section>
  <section>
    <h3>Toolbox</h3>
    <p>
      In 12 years, I've used jQuery, AngularJS, Ionic, React (quite a lot),
      Gatsby, Svelte/Sapper as well as Firebase.
    </p>
    <p>
      Currently,
      <a href="https://svelte.dev">Svelte</a>
      is my favorite tool for developing!
    </p>
  </section>
</div>

<style>
  section {
    margin: var(--gap-4) 0;
  }
</style>
